import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { SessionExpireWrapper } from '../../src';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "sessionexpirewrapper"
    }}>{`SessionExpireWrapper`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { SessionExpireWrapper } from '@mfrm/mfcl'
`}</code></pre>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`The SessionExpireWrapper is wrapper to monitor user inactivty and clear session data upon timing out.`}</p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={SessionExpireWrapper} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <Playground __position={1} __code={'<SessionExpireWrapper\n  timeout={10000}\n  onTimeOut={console.log(\'I have timed out!\')}\n>\n  <>\n    Waiting for 10 (ie 10000ms) seconds. Feel free to update the timeout value\n    in the playground.\n  </>\n</SessionExpireWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      SessionExpireWrapper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <SessionExpireWrapper timeout={10000} onTimeOut={console.log('I have timed out!')} mdxType="SessionExpireWrapper">
    <>Waiting for 10 (ie 10000ms) seconds. Feel free to update the timeout value in the playground.</>
  </SessionExpireWrapper>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      